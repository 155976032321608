exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-news-jsx": () => import("./../../../src/templates/News.jsx" /* webpackChunkName: "component---src-templates-news-jsx" */),
  "component---src-templates-newslist-inject-jsx": () => import("./../../../src/templates/Newslist_inject.jsx" /* webpackChunkName: "component---src-templates-newslist-inject-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/Page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-program-jsx": () => import("./../../../src/templates/Program.jsx" /* webpackChunkName: "component---src-templates-program-jsx" */),
  "component---src-templates-studie-jsx": () => import("./../../../src/templates/Studie.jsx" /* webpackChunkName: "component---src-templates-studie-jsx" */),
  "component---src-templates-support-jsx": () => import("./../../../src/templates/Support.jsx" /* webpackChunkName: "component---src-templates-support-jsx" */)
}

